.p-terminal {
  background: $panelContentBg;
  color: $panelContentTextColor;
  border: $panelContentBorder;
  padding: $panelContentPadding;

  .p-terminal-input {
    font-family: $font-family-sans-serif;
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: $inputTextFontSize;
  }
}
