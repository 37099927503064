:root {
  --bs-box-shadow-sm: 0 1px 2px rgba(56, 65, 74, 0.15);
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--bs-gray-200);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--bs-gray-500);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--bs-gray-600);
}

body {
  min-width: 1200px;
  width: 100%;
  height: 100vh;
  padding-left: 0;
  background-color: #f0f1f7;
  transition: all 200ms linear;

  &.open-sidebar-mainmenu {
    padding-left: var(--width-main-menu);
  }

  app-home {
    min-height: 100%;
    display: block;
  }
}

// ngSelect
.ng-select {
  background-color: var(--bs-white);

  &.is-invalid .ng-select-container {
    border-color: var(--bs-red);
  }

  &.ng-select-single .ng-select-container {
    height: 100% !important;
    cursor: pointer;

    .ng-value-container .ng-input {
      position: absolute;
      left: 0;
      width: 100%;
      top: 0;
      bottom: 0;

      input {
        height: 100%;
        cursor: pointer;
      }
    }
  }

  &.ng-select-multiple .ng-select-container {
    .ng-value-container .ng-placeholder {
      top: 0;
      bottom: 0;
      padding-bottom: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &.ng-select-opened > .ng-select-container,
  &.ng-select-opened > .ng-select-container:hover {
    box-shadow: 0 0 0 1px var(--bs-primary);
    border-color: var(--bs-primary);
  }

  .ng-select-container {
    border: none;
    background: transparent !important;
    border: 1px solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);

    &:hover {
      box-shadow: none;
      border-color: var(--bs-primary);
    }
  }

  &.ng-select-disabled * {
    cursor: not-allowed !important;
  }

  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border-color: #ccc !important;
    box-shadow: none;
  }

  .ng-has-value .ng-placeholder,
  &.ng-select-filtered .ng-placeholder {
    display: none !important;
  }

  .ng-dropdown-panel {
    &.ng-select-bottom {
      margin-top: 1px;
    }
  }
}

// sweetalert2
.swal2-popup.swal2-toast.colored-toast {
  display: flex !important;
  align-items: center;
  font-size: 1em;

  &.swal2-icon-error {
    background-color: var(--bs-danger) !important;
  }
  &.swal2-icon-success {
    background-color: var(--bs-success) !important;
  }

  .swal2-title,
  .swal2-icon {
    color: white !important;
    border-color: #fff !important;
  }

  .swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
    background-color: #fff;
  }
}

// Page section
.page-section {
  box-shadow:
    0 1px 0 0 #e0e3e8,
    0 3px 6px 0 rgba(69, 98, 155, 0.12);

  &.card {
    --bs-card-border-width: 0;
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    .card-header {
      --bs-card-cap-padding-y: 1rem;
      --bs-card-cap-padding-x: 1rem;
      --bs-card-cap-bg: #fff;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }
  }
}

.h-50px {
  height: 50px;
}

.min-h-50px {
  min-height: 50px;
}

.pointer-events {
  pointer-events: none !important;
}

p-chips ul {
  margin-bottom: 0;
  padding-top: 10.5px !important;
  padding-bottom: 10.5px !important;
}

p-dropdown .p-dropdown-label .ng-star-inserted {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.upload {
  position: relative;
  user-select: none;

  & > input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
  }

  & > p {
    margin: 0;
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px dashed #ebebeb;
    font-weight: 500;
    font-size: 1rem;
    color: #c1c1c1;
  }
}
